import { fabric } from 'fabric'
import type { EventBusListener, UseEventBusReturn } from '@vueuse/core'
import { useEventBus } from '@vueuse/core'
import { ref, shallowRef } from 'vue'
import { isArray } from 'lodash-es'
import Utility from './utility'
import WbFrame from './frame'
import type WbConnector from './connector'
import WbDiscussionIcon from './discussionIcon'
import WbGroup from './group'
import utils from '@/services/utils'
import { whiteboardConstants } from '@/models/constants'
import appConfig from '@/services/appConfig'
import type { WhiteboardDetailsModel } from '@/api/t1/model/whiteboardModel'

interface IHistoryItem {
  op: 'add' | 'rem' | 'mod' | 'group' | 'ungroup'
  obj: IWbObject | IWbObject[]
  state?: { org: string, cur: string } | { org: string, cur: string }[]
  groupsAllObjects?: IWbObject[]
}

class Whiteboard {
  boardId: string
  moveToObject?: string
  canvas: fabric.Canvas
  panningOpt = { isDragging: false, lastPosX: 0, lastPosY: 0 }
  frames = shallowRef<WbFrame[]>([])
  discussions = shallowRef<WbDiscussionIcon[]>([])
  history = shallowRef<IHistoryItem[]>([])
  historyPointer = ref(0)
  mentionableUsersAndGroups = ref<IMentionItem[]>([])
  whiteboardDetailsCreatorDetails: Record<string, any> = {}
  private eventBus: { [event in CanvasEventName]: UseEventBusReturn<CanvasEventType<event>, any> }

  constructor(whiteboardId: number, canvasId: string, width: number, height: number, whiteboardDetails?: WhiteboardDetailsModel, moveToObject?: string) {
    this.boardId = `${appConfig.T1Env}${whiteboardId}`
    this.moveToObject = moveToObject
    // Initialize event bus
    this.eventBus = {
      'pan': useEventBus<ICanvasPanEvent>('pan'),
      'zoom': useEventBus<ICanvasZoomEvent>('zoom'),
      'object-added': useEventBus<IObjectAddedEvent>('object-added'),
      'object-removed': useEventBus<IObjectRemovedEvent>('object-added'),
      'object-modified': useEventBus<IObjectModifiedEvent>('object-modified'),
      'object-moving': useEventBus<IObjectModifiedEvent>('object-moving'),
      'object-modified-server': useEventBus<IObjectModifiedEvent>('object-modified-server'),
    }
    // Initialize canvas
    this.canvas = new fabric.Canvas(canvasId, {
      width,
      height,
      fireRightClick: true,
      fireMiddleClick: true,
      stopContextMenu: true,
      preserveObjectStacking: true,
    })
    // Register events
    this.handlePanning()
    this.handleOnWheel()
    this.handleBg()
    this.handleObjectEvents()
    this.setWhiteboardCreatorDetails(whiteboardDetails)
    this.setBoardMentionableList(whiteboardDetails)
  }

  on<T extends CanvasEventName>(event: T, f: EventBusListener<CanvasEventType<T>>) {
    this.eventBus[event].on(f as EventBusListener<CanvasEventType<CanvasEventName>>)
  }

  off<T extends CanvasEventName>(event: T, f: EventBusListener<CanvasEventType<T>>) {
    this.eventBus[event].off(f as EventBusListener<CanvasEventType<CanvasEventName>>)
  }

  // Handle the ability to move around the board
  private handlePanning() {
    this.canvas.on('mouse:down', (opt) => {
      const evt = opt.e

      if (evt.altKey === true || evt.button === whiteboardConstants.mouseButtons.middle || evt.button === whiteboardConstants.mouseButtons.right) {
        this.panningOpt.isDragging = true
        this.canvas.selection = false
        this.panningOpt.lastPosX = evt.clientX
        this.panningOpt.lastPosY = evt.clientY
      }
    })

    this.canvas.on('mouse:move', (opt) => {
      if (this.panningOpt.isDragging && this.canvas.viewportTransform) {
        this.canvas.setCursor('grabbing')
        const e = opt.e

        const vpt = this.canvas.viewportTransform
        vpt[4] += e.clientX - this.panningOpt.lastPosX
        vpt[5] += e.clientY - this.panningOpt.lastPosY

        this.canvas.requestRenderAll()
        this.panningOpt.lastPosX = e.clientX
        this.panningOpt.lastPosY = e.clientY
        this.eventBus.pan.emit({ event: 'pan', viewport: vpt })
      }
    })

    this.canvas.on('mouse:up', () => {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      if (this.canvas.viewportTransform) {
        this.canvas.setViewportTransform(this.canvas.viewportTransform)
        this.panningOpt.isDragging = false
        this.canvas.selection = true
      }
    })
  }

  private handleOnWheel() {
    // handel zoom and pan by track pad
    this.canvas.on('mouse:wheel', (opt) => {
      if (opt.e.ctrlKey) {
        this.handleZooming(opt.e)
      }
      else {
        // pan using track pad
        this.handlePanningByTrackPad(opt.e)
      }
    })
  }

  private handlePanningByTrackPad(event: WheelEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (this.canvas.viewportTransform) {
      const vpt = this.canvas.viewportTransform
      vpt![4] -= event.deltaX
      vpt![5] -= event.deltaY
      this.canvas.requestRenderAll()
      this.eventBus.pan.emit({ event: 'pan', viewport: vpt })
    }
  }

  // Handle the ability to zoom in and out of the board
  private handleZooming(event: WheelEvent) {
    event.preventDefault()
    event.stopPropagation()

    let delta = event.deltaY
    if (delta < 0 && delta > -20) {
      delta = -20
    }
    else if (delta > 0 && delta < 20) {
      delta = 20
    }
    let zoom = this.canvas.getZoom()
    const oldZoom = zoom
    zoom *= 0.999 ** delta
    if (zoom > 5) { zoom = 5 }
    if (zoom < 0.15) { zoom = 0.15 }
    if (zoom !== oldZoom) {
      this.zoom(zoom, { x: event.offsetX, y: event.offsetY })
    }
  }

  private handleBg() {
    this.canvas.setBackgroundColor(new fabric.Pattern({ source: '/images/whiteboard-bg.svg', repeat: 'repeat' }), () => {
      setTimeout(() => this.canvas.requestRenderAll(), 500)
    })
  }

  private handleObjectEvents() {
    this.canvas.on('object:added', (opt) => {
      if (opt.target) {
        if (opt.target instanceof WbFrame) {
          this.loadFrames()
        }
        else if (opt.target instanceof WbDiscussionIcon) {
          this.loadDiscussions()
        }
        this.eventBus['object-added'].emit({ target: opt.target })
      }
    })

    this.canvas.on('object:modified', (opt) => {
      if (opt.target) {
        const wbObject = opt.target as IWbObject
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        if (!opt.ignoreHistory) {
          if (wbObject.type === whiteboardConstants.objectTypes.frame) {
            const wbFrame = wbObject as WbFrame
            if (wbFrame.movingChangedObjsMap.size > 0) {
              const allObjs = this.canvas.getObjects() as Array<IWbObject>
              const wbObjects: IWbObject[] = [wbObject]
              allObjs.forEach((obj) => {
                if (obj.id && wbFrame.movingChangedObjsMap.has(obj.id)) {
                  wbObjects.push(obj)
                }
              })
              this.addToHistory('mod', wbObjects)
            }
            else {
              this.addToHistory('mod', wbObject)
            }
          }
          else if (opt.target instanceof WbDiscussionIcon) {
            this.loadDiscussions()
            this.addToHistory('mod', wbObject)
          }
          else {
            this.addToHistory('mod', wbObject)
          }
        }
        else if (opt.target instanceof WbDiscussionIcon) {
          this.loadDiscussions()
        }
        this.eventBus['object-modified'].emit({ target: opt.target })
      }
    })

    this.canvas.on('object:removed', (opt) => {
      if (opt.target) {
        if (opt.target instanceof WbFrame) {
          this.loadFrames()
        }
        else if (opt.target instanceof WbDiscussionIcon) {
          this.loadDiscussions()
        }
        this.eventBus['object-removed'].emit({ target: opt.target })
      }
    })

    this.canvas.on('object:moving', (opt) => {
      if (opt.target) {
        this.eventBus['object-moving'].emit({ target: opt.target })
        // as discussed with Andre trigger object:moving event for each object in group/selection, so that the connectors can directly listen to each object movement inside selection
        const objectsKey = '_objects'
        if (opt.target.hasOwnProperty(objectsKey)) { // if object is a group/selection
          opt.target[objectsKey].forEach((object) => {
            object.fire('moving', { e: opt.e, target: object, pointer: opt.pointer, transform: opt.transform, _selection: opt.target })
          })
        }
      }
    })
  }

  private loadFrames() {
    this.frames.value = []
    const frames = this.canvas.getObjects(whiteboardConstants.objectTypes.frame) as Array<WbFrame>
    if (frames?.length > 0) {
      frames.sort((a, b) => a.sortOrder - b.sortOrder)
        .forEach(frame => this.frames.value.push(frame))
    }
  }

  emitObjectModifiedServer(obj) {
    if (obj instanceof WbDiscussionIcon) {
      this.loadDiscussions()
    }
    this.eventBus['object-modified-server'].emit({ target: obj })
  }

  private setWhiteboardCreatorDetails(whiteboardDetails: WhiteboardDetailsModel | undefined) {
    if (whiteboardDetails) {
      this.whiteboardDetailsCreatorDetails.createdBy = whiteboardDetails.CreatedBy
      this.whiteboardDetailsCreatorDetails.createdByName = whiteboardDetails.CreatedByName
      this.whiteboardDetailsCreatorDetails.createdByUserName = whiteboardDetails.CreatedByUserName
    }
  }

  private loadDiscussions() {
    this.discussions.value = []
    const discussions = this.canvas.getObjects(whiteboardConstants.objectTypes.discussion) as Array<WbDiscussionIcon>
    if (discussions.length > 0) {
      discussions.forEach((object) => {
        if (object.comments.length) {
          this.discussions.value.push(object)
        }
      })
      this.discussions.value.sort((a, b) => {
        return new Date(b.comments[0].date).getTime() - new Date(a.comments[0].date).getTime()
      })
    }
  }

  updateBoardMentionableList(items) {
    this.mentionableUsersAndGroups.value = []
    items.forEach((item) => {
      this.mentionableUsersAndGroups.value.push({
        type: item.type,
        value: item.id,
        label: item.name,
        subTitle: item.subTitle,
      })
    })
    this.mentionableUsersAndGroups.value.push({
      value: this.whiteboardDetailsCreatorDetails.createdBy,
      type: 'user',
      label: this.whiteboardDetailsCreatorDetails.createdByName,
      subTitle: this.whiteboardDetailsCreatorDetails.createdByUserName,
    })
  }

  setBoardMentionableList(whiteboardDetails: WhiteboardDetailsModel | undefined) {
    if (whiteboardDetails) {
      whiteboardDetails.SharedUsersGroups.forEach((userGroup) => {
        this.mentionableUsersAndGroups.value.push({
          value: userGroup.GroupId,
          type: 'group',
          label: userGroup.Name,
          subTitle: userGroup.AccountName,
        })
      })
      whiteboardDetails.SharedUsers.forEach((user) => {
        this.mentionableUsersAndGroups.value.push({
          value: user.UserId,
          type: 'user',
          label: `${user.FirstName} ${user.LastName}`,
          subTitle: user.UserName,
        })
      })
      this.mentionableUsersAndGroups.value.push({
        value: whiteboardDetails.CreatedBy,
        type: 'user',
        label: whiteboardDetails.CreatedByName,
        subTitle: whiteboardDetails.CreatedByUserName,
      })
    }
  }

  /**
   * Resizes the canvas
   *
   * @param width Width of canvas
   * @param height Height of canvas
   */
  setSize(width: number, height: number) {
    this.canvas.setWidth(width)
    this.canvas.setHeight(height)
    this.canvas.calcOffset()
  }

  zoom(factor: number, point?: IPoint, animate = false) {
    if (point) {
      if (!animate) {
        this.canvas.zoomToPoint(point, factor)
      }
      else {
        fabric.util.animate({
          startValue: this.canvas.getZoom(),
          endValue: factor,
          duration: 300,
          onChange: (zoomValue) => {
            this.canvas.zoomToPoint(point, zoomValue)
            this.canvas.renderAll()
          },
          onComplete: () => {
            this.canvas.renderAll()
          },
        })
      }
    }
    else {
      this.canvas.setZoom(factor)
    }

    this.eventBus.zoom.emit({ event: 'zoom', factor, point })
  }

  /**
   * @description select all objects on canvas
   */
  selectAllObjects() {
    this.canvas.discardActiveObject()
    const objs = this.canvas.getObjects() as Array<IWbObject>
    this.canvas.setActiveObject(new fabric.ActiveSelection(objs.filter(obj => obj.selectable && !obj.lock), { canvas: this.canvas }))
    this.canvas.requestRenderAll()
  }

  /**
   * @description move selected objects from canvas on keyboard up, down, left and right
   */
  moveObject(event: KeyboardEvent, type: 'up' | 'left' | 'right' | 'down' = 'up') {
    const ao = this.canvas.getActiveObject()
    const movementValue = event.ctrlKey || event.metaKey ? 1 : 5
    if (ao) {
      let isAnyActiveObjectLocked = false
      const objectsKey = '_objects'
      if (ao.hasOwnProperty(objectsKey)) {
        const lockedActiveObjects = ao[objectsKey].filter(a => a.lock)
        isAnyActiveObjectLocked = !!lockedActiveObjects.length
      }
      else {
        const lockKey = 'lock'
        isAnyActiveObjectLocked = !!ao[lockKey]
      }
      if (!isAnyActiveObjectLocked) {
        if ((type === 'up' || type === 'down') && ao.top) {
          if (type === 'up') {
            ao.top -= movementValue
          }
          else {
            ao.top += movementValue
          }
        }
        else if ((type === 'left' || type === 'right') && ao.left) {
          if (type === 'left') {
            ao.left -= movementValue
          }
          else {
            ao.left += movementValue
          }
        }
        ao.setCoords()
        this.canvas.requestRenderAll()
        const e = { x: ao.aCoords!.br.x! + ao.width! + 40, y: ao.aCoords!.tl.y! }
        this.canvas.fire('object:moving', { target: ao, e })
        this.canvas.fire('object:modified', { target: ao })
      }
    }
  }

  /**
   * @description copy the selected object on canvas into whiteboard's clipboard and will clear the system clipboard
   */
  copySelectedObjects() {
    const selectedObjects = this.canvas.getActiveObjects()
    if (selectedObjects.length > 0) {
      const copiedObjects: { object: any, relativePosition: { left: number, top: number } }[] = []
      const referenceObject = selectedObjects[0]
      selectedObjects.forEach((obj) => {
        copiedObjects.push({ object: obj.toObject(), relativePosition: { left: (obj.left || 0) - (referenceObject.left || 0), top: (obj.top || 0) - (referenceObject.top || 0) } })
      })
      const objBlob = new Blob([JSON.stringify(copiedObjects)], { type: 'text/plain' })
      navigator.clipboard.write([new ClipboardItem({ 'text/plain': objBlob })])
    }
  }

  /**
   * @description being selected objects to front
   */
  bringSelectedObjectsToFront() {
    const objs = this.canvas.getActiveObjects()
    objs.forEach(obj => obj.bringToFront())
    this.canvas.requestRenderAll()
  }

  /**
   * @description send selected objects to back
   */
  sendSelectedObjectsToBack() {
    const objs = this.canvas.getActiveObjects()
    objs.forEach(obj => obj.sendToBack())
    this.canvas.requestRenderAll()
  }

  centerObject(obj: IWbObject) {
    this.canvas.discardActiveObject()
    const viewPadding = { x: 100, y: 100 } // <- Adjust this value to the desired empty space around the frame when zooming

    const availableWidth = (this.canvas.width || 0) - viewPadding.x * 2
    const availableHeight = (this.canvas.height || 0) - viewPadding.y * 2
    const frameWidth = (obj.width || 0) * (obj.scaleX || 0)
    const frameHeight = (obj.height || 0) * (obj.scaleY || 0)
    const widthZoomToFit = availableWidth / frameWidth
    const heightZoomToFit = availableHeight / frameHeight
    const zoomLevel = Math.min(widthZoomToFit, heightZoomToFit)

    const newLeft = viewPadding.x + (-(obj.left || 0) * zoomLevel) + (availableWidth - frameWidth * zoomLevel) / 2
    const newTop = viewPadding.y + (-(obj.top || 0) * zoomLevel) + (availableHeight - frameHeight * zoomLevel) / 2

    this.zoom(zoomLevel)
    this.canvas.setViewportTransform([zoomLevel, 0, 0, zoomLevel, newLeft, newTop])
    // if(setActive) {
    //   this.canvas.setActiveObject(obj)
    // }
    this.canvas.requestRenderAll()
  }

  moveToView(obj: IWbObject) {
    this.canvas.discardActiveObject()
    const viewPadding = { x: 10, y: 10 } // <- Adjust this value to the desired empty space around the frame when zooming

    const availableWidth = (this.canvas.width || 0) - viewPadding.x * 2
    const availableHeight = (this.canvas.height || 0) - viewPadding.y * 2
    const frameWidth = (obj.width || 0) * (obj.scaleX || 0)
    const frameHeight = (obj.height || 0) * (obj.scaleY || 0)
    const widthZoomToFit = availableWidth / frameWidth
    const heightZoomToFit = availableHeight / frameHeight
    const zoomLevel = Math.min(widthZoomToFit, heightZoomToFit, this.canvas.getZoom())
    const newLeft = viewPadding.x + (-(obj.left || 0) * zoomLevel) + (availableWidth - frameWidth * zoomLevel) / 2
    const newTop = viewPadding.y + (-(obj.top || 0) * zoomLevel) + (availableHeight - frameHeight * zoomLevel) / 2
    this.zoom(zoomLevel)
    this.canvas.setViewportTransform([zoomLevel, 0, 0, zoomLevel, newLeft, newTop])
    this.canvas.requestRenderAll()
    obj.setCoords()
    this.canvas.setActiveObject(obj)
  }

  addObjectsFromJson(objects: any[], isPaste = false, addToHistory = false) {
    return new Promise<fabric.Object[]>((resolve) => {
      fabric.util.enlivenObjects(objects, (newObjs) => {
        this.manageEnlivenConnectors(newObjs, isPaste)
        this.addObjects(newObjs, addToHistory)
        resolve(newObjs)
      }, '')
    })
  }

  addGroupFromJson(groupData: any) {
    return new Promise<fabric.Group>((resolve) => {
      fabric.util.enlivenObjects(groupData.objects, (newObjs) => {
        this.manageEnlivenConnectors(newObjs, false)

        const group = new WbGroup(newObjs, {
          ...groupData,
        })

        this.canvas.add(group)
        this.canvas.renderAll()

        resolve(group)
      }, '')
    })
  }

  manageEnlivenConnectors(objects: Array<IWbObject>, isPaste: boolean) {
    const indexedObjects = objects.reduce((acu, cur) => (acu[cur.id!] = cur) && acu, {})
    let indexedObjectsByOldId = {}
    if (isPaste) {
      // on paste the newly create objects will have new id's, when copy objects their oldIds will be stored in oldId property
      indexedObjectsByOldId = objects.reduce((acu, cur) => (acu[(cur as any).oldId] = cur) && acu, {})
    }
    objects.forEach((object) => {
      if (object.type === whiteboardConstants.objectTypes.connector) {
        const connectorObject = object as WbConnector
        let startObject = indexedObjects[connectorObject.startObjectId]
        let endObject = indexedObjects[connectorObject.endObjectId!]
        if (isPaste) {
          // if paste update start and end object id to point to newly created objects
          startObject = indexedObjects[indexedObjectsByOldId[connectorObject.startObjectId].id]
          endObject = indexedObjects[indexedObjectsByOldId[connectorObject.endObjectId!].id]
          connectorObject.setProp('startObjectId', startObject.id)
          connectorObject.setProp('endObjectId', endObject.id)
        }
        Utility.addWbConnectorsConnectedObjectListeners(this.canvas, connectorObject, startObject, endObject)
      }
    })
  }

  performMoveToObject() {
    if (utils.isDefined(this.moveToObject)) {
      const frame = this.frames.value.find(x => x.id === this.moveToObject)
      if (frame) {
        this.centerObject(frame)
      }
    }
  }

  addToHistory(op: 'add' | 'rem' | 'mod' | 'group' | 'ungroup', obj: IWbObject | IWbObject[]) {
    const maxUndoHistory = 10

    if (this.historyPointer.value > 0) {
      for (let index = 0; index < this.historyPointer.value; index++) {
        this.history.value.pop()
      }
      this.historyPointer.value = 0
    }
    else {
      this.historyPointer.value = 0
    }
    if (this.history.value.length >= maxUndoHistory) {
      this.history.value.shift()
    }

    if (obj && !Array.isArray(obj) && obj.type === 'activeSelection') {
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      obj = obj.getObjects()
    }
    const ao = this.canvas.getActiveObjects()
    this.canvas.discardActiveObject()

    if (op === 'group') {
      const states: { org: string, cur: string }[] = []
      this.history.value.push({ op, obj, state: states })
    }
    else if (op === 'ungroup') {
      const states: { org: string, cur: string }[] = []
      this.history.value.push({ op, obj, state: states })
    }
    else if (Array.isArray(obj)) {
      const states: { org: string, cur: string }[] = []
      const simpleObjects: any[] = []

      obj.forEach((itm: IWbObject) => {
        const org = JSON.stringify(utils.getStateProperties(itm))
        itm.saveState()
        const cur = JSON.stringify(utils.getStateProperties(itm))
        if (op === 'mod') { states.push({ org, cur }) }
        simpleObjects.push(itm.toObject())
      })

      this.history.value.push({ op, obj: simpleObjects, state: op === 'mod' ? states : undefined })
    }
    else if (obj) {
      const org = JSON.stringify(utils.getStateProperties(obj))
      obj.saveState()
      const cur = JSON.stringify(utils.getStateProperties(obj))
      this.history.value.push({ op, obj: obj.toObject(), state: op === 'mod' ? { org, cur } : undefined })
    }

    this.history.value = [...this.history.value]

    if (ao && ao.length > 0) {
      if (ao.length === 1) {
        this.canvas.setActiveObject(ao[0])
      }
      else {
        this.canvas.setActiveObject(new fabric.ActiveSelection(ao, { canvas: this.canvas }))
      }
    }
  }

  drawFromHistory(undo: boolean) {
    this.canvas.discardActiveObject()

    const histItem = this.history.value[this.history.value.length - this.historyPointer.value - 1]
    let newSelection: IWbObject | IWbObject[] | null = null

    const allObjs = this.canvas.getObjects() as Array<IWbObject>

    if (histItem.op === 'mod') {
      if (Array.isArray(histItem.obj)) {
        newSelection = []
        for (let index = 0; index < histItem.obj.length; index++) {
          const wbObj = allObjs.find(itm => itm.id === histItem.obj[index].id)
          if (wbObj && histItem.state && Array.isArray(histItem.state)) {
            wbObj.setOptions(JSON.parse(undo ? histItem.state[index].org : histItem.state[index].cur))
            wbObj.dirty = true
            wbObj.setCoords()
            wbObj.saveState()
            utils.addOrRemoveChildToFrames(this.frames.value, wbObj)
            this.canvas.fire('object:modified', { target: wbObj, ignoreHistory: true })
            newSelection.push(wbObj)
          }
          else {
            console.warn('Cannot find element with id', histItem.obj[index].id)
          }
        }
      }
      else {
        const histItemObj = histItem.obj as IWbObject
        const wbObj = allObjs.find(itm => itm.id === histItemObj.id)
        if (wbObj && histItem.state && !Array.isArray(histItem.state)) {
          wbObj.setOptions(JSON.parse(undo ? histItem.state.org : histItem.state.cur))
          wbObj.dirty = true
          wbObj.setCoords()
          wbObj.saveState()
          utils.addOrRemoveChildToFrames(this.frames.value, wbObj)
          this.canvas.fire('object:modified', { target: wbObj, ignoreHistory: true })
          newSelection = wbObj
        }
        else {
          console.warn('Cannot find element with id', histItemObj.id)
        }
      }
    }
    else if (histItem.op === 'group' || histItem.op === 'ungroup') {
      if ((histItem.op === 'group' && undo) || (histItem.op === 'ungroup' && !undo)) {
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        const element = allObjs.find(itm => itm.id === histItem.obj.id)
        if (element) {
          this.ungroupObject(element, false)
        }
        else {
          // eslint-disable-next-line ts/ban-ts-comment
          // @ts-expect-error
          console.warn('Cannot find group with id', histItem.obj.id)
        }
      }
      else {
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        const elements = histItem.groupsAllObjects
          .map(obj => allObjs.find(itm => itm.id === obj.id))
          .filter((obj): obj is IWbObject => obj !== undefined)

        this.groupObjects(elements, histItem.obj, false)
      }
    }
    else if ((histItem.op === 'add' && undo) || (histItem.op === 'rem' && !undo)) {
      const objectsToRemove: IWbObject[] = []
      if (Array.isArray(histItem.obj)) {
        histItem.obj.forEach((itm) => {
          const element = allObjs.find(obj => obj.id === itm.id)
          if (element) {
            objectsToRemove.push(element)
          }
          else {
            console.warn('Cannot find element with id', itm.id)
          }
        })
      }
      else {
        const histItemObj = histItem.obj as IWbObject
        const element = allObjs.find(obj => obj.id === histItemObj.id)
        if (element) {
          objectsToRemove.push(element)
        }
        else {
          console.warn('Cannot find element with id', histItemObj.id)
        }
      }
      this.removeObjects(objectsToRemove)
    }
    else {
      const objectsToAdd: IWbObject[] = []
      if (Array.isArray(histItem.obj)) {
        objectsToAdd.push(...histItem.obj)
      }
      else {
        objectsToAdd.push(histItem.obj)
      }
      fabric.util.enlivenObjects(objectsToAdd, (newObjs) => {
        this.addObjects(newObjs)
      }, '')
    }

    this.canvas.requestRenderAll()
  }

  addObjects(newObjects: IWbObject[], addToHistory: boolean = false) {
    if (addToHistory) {
      this.addToHistory('add', newObjects.length > 1 ? newObjects : newObjects[0])
    }

    newObjects.forEach((object) => {
      this.canvas.add(object)
      object.saveState()
    })

    this.canvas.requestRenderAll()
  }

  removeObjects(objects: IWbObject[], addToHistory: boolean = false) {
    if (addToHistory) {
      this.addToHistory('rem', objects.length > 1 ? objects : objects[0])
    }

    objects.forEach((object) => {
      this.canvas.remove(object)
    })

    this.canvas.discardActiveObject().requestRenderAll()
  }

  undo() {
    if (this.historyPointer.value >= this.history.value.length) { return }

    this.drawFromHistory(true)
    this.historyPointer.value++
  }

  redo() {
    if (this.historyPointer.value <= 0) { return }

    this.historyPointer.value--
    this.drawFromHistory(false)
  }

  groupObjects(objects: IWbObject[], groupSpecs?, addToHistory?) {
    if (!objects || !isArray(objects) || objects.length <= 0) {
      return
    }

    this.canvas.discardActiveObject()

    const group = new WbGroup(null, groupSpecs)

    objects.forEach((obj) => {
      if (obj.type !== 'hotspot') {
        group.addWithUpdate(obj)
      }
    })

    group.saveState()
    this.canvas.add(group)
    this.removeObjects(objects, false)
    group.setCoords()

    if (addToHistory) {
      this.addToHistory('group', group as IWbObject)
    }

    this.canvas.setActiveObject(group)
    return group
  }

  ungroupObject(group, addToHistory = false) {
    if (!group || group.type !== 'group') {
      return
    }

    if (addToHistory) {
      this.addToHistory('ungroup', group)
    }

    let sel = group.toActiveSelection()
    const objects = sel.getObjects()

    // add the ungrouped objects to history
    const histItem = this.history.value[this.history.value.length - this.historyPointer.value - 1]
    histItem.groupsAllObjects = objects

    this.canvas.discardActiveObject()
    objects.forEach((object) => {
      object.saveState()
    })
    sel = new fabric.ActiveSelection(objects, { canvas: this.canvas })
    this.canvas.setActiveObject(sel)
    this.canvas.requestRenderAll()
    return sel
  }
}

export interface ICanvasZoomEvent {
  event: 'zoom'
  point?: IPoint
  factor: number
}

export interface ICanvasPanEvent {
  event: 'pan'
  viewport: number[]
}

interface IObjectAddedEvent {
  target: fabric.Object
}

interface IObjectRemovedEvent {

}

interface IObjectModifiedEvent {
  target: fabric.Object
}

type CanvasEventName = 'zoom' | 'pan' | 'object-added' | 'object-removed' | 'object-modified' | 'object-moving' | 'object-modified-server'

type CanvasEventType<T> =
  T extends 'zoom' ? ICanvasZoomEvent :
    T extends 'pan' ? ICanvasPanEvent :
      T extends 'object-added' ? IObjectAddedEvent :
        T extends 'object-removed' ? IObjectRemovedEvent :
          T extends 'object-modified' ? IObjectModifiedEvent :
            T extends 'object-moving' ? IObjectModifiedEvent :
              T extends 'object-modified-server' ? IObjectModifiedEvent :
                never

export default Whiteboard
