<template>
  <div class="">
    <div
      v-show="toolbarVisible" ref="toolbarRef" class="absolute z-[1000] flex bg-grey-light rounded-lg shadow-toolbar"
      :style="{ top: `${toolbarPos.y}px`, left: `${toolbarPos.x}px` }"
    >
      <div v-if="lockProp" class="flex items-center">
        <div v-tooltip="'Unlock'" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-lock" @click="onLockPropChange(!lockProp)" />
        </div>
        <div v-if="editableProps.addDiscussion" v-tooltip="editableProps.addDiscussion.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-comment" @click="onAddDiscussion" />
        </div>
      </div>
      <div v-for="(p, k) in editableProps" v-else :key="k" class="flex items-center">
        <div
          v-if="p.type === 'border'" v-tooltip="p.name" class="rounded-full w-6 h-6 m-1.5 cursor-pointer border-solid"
          :style="{ borderColor: borderPropColor, borderWidth: `${borderPropThickness}px` }" @click="onOpenSelection($event, p.type)"
        />
        <div
          v-else-if="p.type === 'color'" v-tooltip="p.name" class="rounded-full w-6 h-6 m-1.5 cursor-pointer"
          :style="{ backgroundColor: colorProp }" @click="onOpenSelection($event, p.type)"
        />
        <div
          v-else-if="p.type === 'font'" v-tooltip="p.name" class="w-auto m-1.5 text-base font-bold cursor-pointer"
          @click="onOpenSelection($event, p.type)" v-text="fontProp"
        />
        <div
          v-else-if="p.type === 'fontSize'" v-tooltip="p.name" class="w-auto m-1.5 text-base font-bold cursor-pointer"
          @click="onOpenSelection($event, p.type)" v-text="availableFontSizes[fontSizeProp] || fontSizeProp"
        />
        <div
          v-else-if="p.type === 'textColor'" v-tooltip="p.name" class="w-6 text-center text-base font-bold m-1.5 cursor-pointer"
          :style="{ borderBottom: `5px solid ${textColorProp}` }" @click="onOpenSelection($event, p.type)" v-text="'A'"
        />
        <div v-else-if="p.type === 'textAlign'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" :icon="availableTextAligns[textAlignProp]" @click="onOpenSelection($event, p.type)" />
        </div>
        <div
          v-else-if="p.type === 'fontStyle'" v-tooltip="p.name" class="w-6 border-b-4 border-black italic text-center text-base font-bold m-1.5 cursor-pointer"
          @click="onOpenSelection($event, p.type)" v-text="'B'"
        />
        <div
          v-else-if="p.type === 'size'" v-tooltip="p.name" class="w-auto m-1.5 text-base font-bold cursor-pointer"
          @click="onOpenSelection($event, p.type)" v-text="sizeProp"
        />
        <div
          v-else-if="p.type === 'shapeType'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer"
          @click="onOpenSelection($event, p.type)"
        >
          <svg viewBox="0 0 50 50">
            <path :style="{ fill: 'none', stroke: '#000000', strokeWidth: '3px' }" :d="availableShapes[shapeTypeProp]" />
          </svg>
        </div>
        <div
          v-else-if="p.type === 'frameSize'" v-tooltip="p.name" class="w-auto m-1.5 text-base font-bold cursor-pointer"
          @click="onOpenSelection($event, p.type)" v-text="utils.capitalizeFirstLetter(frameSizeProp)"
        />
        <div v-else-if="p.type === 'lock'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-lock-open" @click="onLockPropChange(!lockProp)" />
        </div>
        <div
          v-else-if="p.type === 'imageType'" v-tooltip="p.name" class="w-auto m-1.5 text-base font-bold cursor-pointer"
          @click="onOpenSelection($event, p.type)" v-text="assetKeyProp || 'Type'"
        />
        <div
          v-else-if="p.type === 'scale'" v-tooltip="p.name" class="w-auto m-1.5 text-base font-bold cursor-pointer"
          @click="onOpenSelection($event, p.type)" v-text="`${scaleProp}%`"
        />
        <div v-else-if="p.type === 'addModelDetails'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-plus" @click="onAddModelDetails" />
        </div>
        <div v-else-if="p.type === 'addArticleDetails'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-plus" @click="onAddArticleDetails" />
        </div>
        <div
          v-else-if="p.type === 'showLabels'" class="m-1.5 text-base cursor-pointer" :class="{ 'text-primary-500': showLabelsProp }"
          @click="onShowLabelsPropChange(!showLabelsProp)" v-text="p.name"
        />
        <div v-else-if="p.type === 'attributes'" class="w-40 mx-1.5">
          <tx-select
            v-model="attributesProp" :data="availableAttributes" value-prop="SystemName" display-prop="DisplayName"
            :placeholder="p.name" filterable clearable multiple-values show-one @change="onAttributePropChange"
          />
        </div>
        <div v-else-if="p.type === 'assignImage' && allowExternalImageToArticle" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-image" @click="onAssignImage" />
        </div>
        <div v-else-if="p.type === 'addDiscussion'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-comment" @click="onAddDiscussion" />
        </div>
      </div>

      <!-- MORE OPTIONS -->
      <tx-button
        v-show="!isEmpty(selectedObjectSubMenu)" v-tooltip="t('general.more').toLowerCase()" type="icon" faicon="fa-light fa-ellipsis-stroke-vertical"
        class="float-right" @click="onOpenSelection($event as MouseEvent, 'subMenu')"
      />
    </div>

    <!-- DROPDOWNS -->
    <div
      v-show="dropdownVisible === 'border'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2 text-center"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <input
        v-model="borderPropThickness" type="range" class="w-100" min="1" max="10"
        @change="onBorderPropChange(borderPropColor, borderPropThickness)"
      >
      <div
        v-for="color in availableColors" :key="color" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :style="{ backgroundColor: color }" :class="{ 'outline outline-blue-50 outline-2 outline-offset-1': borderPropColor === color }"
        @click="onBorderPropChange(color, borderPropThickness)"
      />
    </div>

    <div
      v-show="dropdownVisible === 'color'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="color in availableColors" :key="color" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :style="{ backgroundColor: color }" :class="{ 'outline outline-blue-50 outline-2 outline-offset-1': colorProp === color }"
        @click="onColorPropChange(color)"
      />
    </div>

    <div
      v-show="dropdownVisible === 'font'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="font in availableFonts" :key="font" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': fontProp === font }" @click="onFontPropChange(font)" v-text="font"
      />
    </div>

    <div
      v-show="dropdownVisible === 'fontSize'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-20 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <input v-model="fontSizeProp" type="number" min="1" max="100" @change="onFontSizePropChange(fontSizeProp)">
      <div
        v-for="(val, key) in availableFontSizes" :key="key" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': fontSizeProp === key }" @click="onFontSizePropChange(key)" v-text="val"
      />
    </div>

    <div
      v-show="dropdownVisible === 'textColor'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="textColor in availableColors" :key="textColor" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :style="{ backgroundColor: textColor }" :class="{ 'outline outline-blue-50 outline-2 outline-offset-1': textColorProp === textColor }"
        @click="onTextColorPropChange(textColor)"
      />
    </div>

    <div
      v-show="dropdownVisible === 'textAlign'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-30 p-2"
      :style="{ left: `${propDropdownPos.x - 50}px`, top: `${propDropdownPos.y}px` }"
    >
      <font-awesome-icon
        v-for="(icon, alignType) in availableTextAligns" :key="alignType" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :icon="icon" @click="onTextAlignPropChange(alignType)"
      />
    </div>

    <div
      v-show="dropdownVisible === 'fontStyle'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-30 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-tooltip="t('general.bold')" class="float-left m-1.5 text-base cursor-pointer" :class="{ 'text-primary-500': boldProp }"
        @click="onBoldPropChange(!boldProp)" v-text="'B'"
      />
      <div
        v-tooltip="t('general.italic')" class="float-left m-1.5 text-base italic cursor-pointer" :class="{ 'text-primary-500': italicProp }"
        @click="onItalicPropChange(!italicProp)" v-text="'I'"
      />
    </div>

    <div
      v-show="dropdownVisible === 'size'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-8 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="size in availableSizes" :key="size" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': sizeProp === size }" @click="onSizePropChange(size)" v-text="size"
      />
    </div>

    <div
      v-show="dropdownVisible === 'shapeType'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="(path, shapeType) in availableShapes" :key="shapeType" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        @click="onShapeTypePropChange(shapeType)"
      >
        <svg viewBox="0 0 50 50">
          <path :style="{ fill: 'none', stroke: shapeTypeProp === shapeType ? '#1484f8' : '#000000', strokeWidth: '3px' }" :d="path" />
        </svg>
      </div>
    </div>

    <div
      v-show="dropdownVisible === 'frameSize'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-42 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="(path, frameSize) in availableFrameSizes" :key="frameSize" class="rounded-full float-left m-1.5 cursor-pointer"
        @click="onFrameSizePropChange(frameSize)"
      >
        <div class="w-6 h-6">
          <svg viewBox="0 0 24 24">
            <path :style="{ fill: 'none', stroke: frameSizeProp === frameSize ? '#1484f8' : '#000000' }" :d="path" />
          </svg>
        </div>
        <div class="text-center">
          {{ utils.capitalizeFirstLetter(frameSize) }}
        </div>
      </div>
    </div>

    <div
      v-show="dropdownVisible === 'imageType'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="asset in availableAssets" :key="asset.Id" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': assetKeyProp === asset.Key }" @click="onAssetPropChange(asset)" v-text="asset.Key"
      />
    </div>

    <div
      v-show="dropdownVisible === 'scale'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-20 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <input v-model="scaleProp" type="number" min="1" max="100" @change="onScalePropChange(scaleProp)">
      <div
        v-for="scale in availableScales" :key="scale" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': scaleProp === scale }" @click="onScalePropChange(scale)" v-text="`${scale}%`"
      />
    </div>

    <div
      v-show="selectedObj?.type === whiteboardConstants.objectTypes.discussion"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-60 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <manage-discussion
        v-if="selectedObj?.type === whiteboardConstants.objectTypes.discussion"
        :discussion="selectedObj" :whiteboard-id="whiteboardId" :whiteboard="whiteboard" @delete="onDeleteDiscussion"
      />
    </div>

    <div
      v-show="dropdownVisible === 'subMenu'" class="absolute z-[1000] bg-grey-light rounded-sm shadow-toolbar w-60 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="subMenu in selectedObjectSubMenu"
        :key="subMenu.action" class="flex items-center px-1 text-base leading-9 truncate transition rounded-sm cursor-pointer hover:bg-gray-200 motion-reduce:transition-none motion-reduce:hover:transform-none"
        @click="onSelectedObjectSubMenuClick(subMenu.action)"
      >
        <tx-button type="icon" :icon="subMenu.icon" :faicon="subMenu.faicon" />
        <p class="ml-1">
          {{ subMenu.label }}
        </p>
      </div>
    </div>

    <input
      v-show="false" ref="assignImageRef" type="file" accept="image/png, image/jpg, image/jpeg"
      @change="onAssignImageChange"
    >

    <placeholder-image-dialog ref="placeholderImageDialogRef" @cancel="resetAssignImage" @confirm="onAssignImageConfirm" />
  </div>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { fabric } from 'fabric'
import { useI18n } from 'vue-i18n'
import { useElementSize, useWindowSize } from '@vueuse/core'
import { computed, onUnmounted, ref, watchEffect } from 'vue'
import WbArticleDetails from '../services/articleDetails'
import type Whiteboard from '../services/whiteboard'
import type { ShapeType } from '../services/shape'
import WbShape from '../services/shape'
import type { FrameSize } from '../services/frame'
import WbFrame from '../services/frame'
import type WbImage from '../services/image'
import WbDiscussionIcon from '../services/discussionIcon'
import type WbArticleImage from '../services/articleImage'
import WbModelDetails from '../services/modelDetails'
import type WbModelImage from '../services/modelImage'
import Utility from '../services/utility'
import PlaceholderImageDialog from './PlaceholderImageDialog.vue'
import ManageDiscussion from './ManageDiscussion.vue'
import type DuneAsset from '@/models/duneAsset'
import type MyArticle from '@/models/myArticle'
import type { CreateUpdateAssetModel } from '@/api/dune/model/assetModel'
import { getArticleAssets } from '@/api/t1/article'
import { appConstants, imageConstants, privileges, whiteboardConstants } from '@/models/constants'
import utils from '@/services/utils'
import TxButton from '@/shared/components/TxButton.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

const props = defineProps<{
  whiteboardId: number
  whiteboard?: Whiteboard
}>()

const emit = defineEmits<{
  (e: 'openTextEditingDialog', object: IWbObject)
  (e: 'copyModel', activeObject: WbArticleImage | WbModelImage)
}>()

const { t } = useI18n()
const userStore = useUserStore()
const notificationsStore = useNotificationStore()

const toolbarVisible = ref(false)
const editableProps = ref<Record<string, IWbObjectProp>>({})
const selectedObjectSubMenu = ref<Record<string, IWObjectActions>>({})
const winSize = useWindowSize()
const toolbarRef = ref<HTMLElement>()
const assignImageRef = ref<HTMLInputElement>()
const placeholderImageDialogRef = ref<InstanceType<typeof PlaceholderImageDialog>>()
const toolbarSize = useElementSize(toolbarRef)
const selectedObj = ref()
const doesSelectionContainsModelDetails = ref(false) // TODO: this flag is not required if selectedObj hold all the selected objects (probably logic on selectedObj should be revisited)
const doesSelectionContainsArticleDetails = ref(false) // TODO: this flag is not required if selectedObj hold all the selected objects (probably logic on selectedObj should be revisited)
const selObjCoords = ref<[fabric.Point, fabric.Point, fabric.Point, fabric.Point]>()
const availableColors = ['transparent', '#fff9b1', '#d5f692', '#f5d128', '#c9df56', '#ff9d48', '#93d275', '#f16c7f', '#67c6c0', '#ea94bb', '#6cd8fa', '#ffcee0', '#a6ccf5', '#be88c7', '#7b92ff', '#000000', '#FFFFFF']
const availableFonts = ['Arial', 'Verdana', 'Helvetica', 'Tahoma', 'Trebuchet MS', 'Times New Roman', 'Georgia', 'Garamond', 'Courier New', 'Brush Script MT']
const availableFontSizes = { '-1': 'Auto', '4': '4', '8': '8', '10': '10', '12': '12', '14': '14', '18': '18', '20': '20', '24': '24', '32': '32', '48': '48', '64': '64' }
const availableTextAligns = { left: 'fa-light fa-align-left', center: 'fa-light fa-align-center', right: 'fa-light fa-align-right' }
const availableSizes = ['S', 'M', 'L']
const availableShapes = WbShape.shapes
const availableFrameSizes = WbFrame.frameSizes
const availableAssets = ref<DuneAsset[]>([])
const availableScales = [100, 50, 25, 10, 5]
const dropdownVisible = ref('')
const propDropdownPos = ref<IPoint>({ x: 0, y: 0 })
const borderPropThickness = ref(1)
const borderPropColor = ref('#000000')
const colorProp = ref('#93d275')
const fontProp = ref('Helvetica')
const fontSizeProp = ref('-1')
const textColorProp = ref('#000000')
const textAlignProp = ref('center')
const boldProp = ref(false)
const italicProp = ref(false)
const sizeProp = ref('M')
const shapeTypeProp = ref('rectangle')
const frameSizeProp = ref('custom')
const lockProp = ref(false)
const assetKeyProp = ref('')
const scaleProp = ref(100)
const showLabelsProp = ref(true)
const attributesProp = ref([])

let selectedArticleImage: WbArticleImage | undefined

const allowExternalImageToArticle = computed(() => {
  let isValid = userStore.userProfile.isValidPrivilege(privileges.whiteboard.allowExternalImageToArticle)
  if (isValid && userStore.activeCatalog) {
    isValid = utils.isValidStringValue(userStore.activeCatalog.ContextReadWriteKey)
  }
  return true
})

const availableAttributes = computed(() => {
  const excludeAttributeSystemName = new Set(['_DeliveryDates', '_Segmentations', '_FavoriteTags'])
  const availableAttributes = Object.values(userStore.myAttributes!).filter(attribute => !excludeAttributeSystemName.has(attribute.SystemName) && (!doesSelectionContainsModelDetails.value || attribute.IsModelLevel))
  // selection does not contains article details, add SKU fields
  if (doesSelectionContainsModelDetails.value && !doesSelectionContainsArticleDetails.value) {
    const SKUField = { ...appConstants.staticFieldTemplate }
    SKUField.DisplayName = `${userStore.activeCatalog!.Season} SKU`
    SKUField.SystemName = userStore.activeCatalog!.CatalogCode.toString()
    availableAttributes.push(SKUField)
    for (const key in userStore.linkedCatalogDetails) {
      const SKUField = { ...appConstants.staticFieldTemplate }
      SKUField.DisplayName = `${userStore.linkedCatalogDetails[key].Season} SKU`
      SKUField.SystemName = userStore.linkedCatalogDetails[key].CatalogCode.toString()
      availableAttributes.push(SKUField)
    }
  }
  return availableAttributes
})

watchEffect(() => {
  if (props.whiteboard) {
    attach(props.whiteboard)
  }
})

onUnmounted(() => {
  if (props.whiteboard) {
    detach(props.whiteboard)
  }
})

function attach(obj: Whiteboard) {
  obj.canvas.on('selection:created', onSelection)
  obj.canvas.on('selection:updated', onSelection)
  obj.canvas.on('selection:cleared', onSelection)
  obj.canvas.on('object:moving', (e) => {
    onSelection(e)
    resetSelectedArticleImage()
    // if moving object is an image and allowExternalImageToArticle is true, then highligh the article image if image intersects it and initialize selectedArticleImage (assign this image to the intersected article)
    if (e.target?.type === whiteboardConstants.objectTypes.image && e.pointer && allowExternalImageToArticle.value) {
      const articleImages = obj.canvas.getObjects(whiteboardConstants.objectTypes.articleImage)
      for (let i = 0; i < articleImages.length; i++) {
        const articleImage = articleImages[i] as WbArticleImage
        // if image intersect with an article image and selectedArticleImage is not yet assigned, then highlight the article image and assign it to selectedArticleImage
        if (!selectedArticleImage && articleImage.midIntersectsWithObject(e.target)) {
          articleImage.highlight()
          selectedArticleImage = articleImage
        }
        else {
          articleImage.dim()
        }
      }
    }
  })
  obj.canvas.on('object:scaling', onSelection)
  obj.on('zoom', onSelection)
  obj.canvas.on('mouse:up', (e) => {
    utils.addOrRemoveChildToFrames(obj.frames.value, e.target as IWbObject)
    if (userStore.activeCatalog && selectedArticleImage && selectedArticleImage.highlighted) {
      const selectedImage = props.whiteboard?.canvas.getActiveObject() as WbImage
      if (selectedImage) {
        const url = selectedImage.getSrc()
        fetch(url)
          .then(response => response.blob())
          .then((blob) => {
            const filename = url.split('/').pop()
            if (filename) {
              const file = new File([blob], filename)
              // assign image to article image (drag an image over an article image on whiteboard)
              openPlaceholderImageDialog(selectedArticleImage!, file)
            }
          })
      }
    }
  })
  obj.canvas.on('dragover', (e) => {
    resetSelectedArticleImage()
    if (allowExternalImageToArticle.value) {
      const articleImages = obj.canvas.getObjects(whiteboardConstants.objectTypes.articleImage)
      for (let i = 0; i < articleImages.length; i++) {
        const articleImage = articleImages[i] as WbArticleImage
        if (e.target === articleImage) {
          articleImage.highlight()
        }
        else {
          articleImage.dim()
        }
      }
    }
  })
  obj.canvas.on('drop', (e) => {
    e.e.preventDefault()
    const dragEvent = e.e as DragEvent
    if (e.target?.type === whiteboardConstants.objectTypes.articleImage && allowExternalImageToArticle.value && dragEvent.dataTransfer?.files.length) {
      let selectedFile: File | undefined
      const articleImage = e.target as WbArticleImage
      for (let i = 0; i < dragEvent.dataTransfer.files.length; i++) {
        const image = dragEvent.dataTransfer.files[i]
        if (imageConstants.validImageFormats.has(image.type)) {
          selectedFile = image
          break
        }
      }
      if (selectedFile) {
        selectedArticleImage = articleImage
        // assign an image to selectedArticleImage by dropping an image form user's machine
        openPlaceholderImageDialog(selectedArticleImage, selectedFile)
      }
      else {
        articleImage.dim()
      }
    }
  })
}

function detach(obj: Whiteboard) {
  obj.canvas.off('selection:created')
  obj.canvas.off('selection:updated')
  obj.canvas.off('selection:cleared')
  obj.canvas.off('object:moving')
  obj.canvas.off('object:scaling')
  obj.canvas.off('mouse:up')
  obj.canvas.off('dragover')
  obj.canvas.off('drop')
}

const toolbarPos = computed(() => {
  if (!selObjCoords.value) { return { x: 0, y: 0 } }
  const margin = 20
  const canvasZoom = props.whiteboard!.canvas.getZoom() || 0
  const vpCoords = props.whiteboard!.canvas.vptCoords
  let x = (selObjCoords.value[0].x - vpCoords!.tl.x)
  let y = (selObjCoords.value[0].y - vpCoords!.tl.y)
  const bottomY = (selObjCoords.value[3].y - vpCoords!.tl.y) * canvasZoom
  const width = selObjCoords.value[1].x - selObjCoords.value[0].x

  x = x * canvasZoom + (width * canvasZoom / 2) - toolbarSize.width.value / 2
  const dynamicAdjustment = 28 * canvasZoom
  y = (y * canvasZoom - toolbarSize.height.value - margin) - dynamicAdjustment

  x = Math.min(Math.max(x, 0), winSize.width.value - toolbarSize.width.value - 100)
  if (y < 50) {
    if (bottomY + margin + toolbarSize.height.value > winSize.height.value) {
      y = 50
    }
    else {
      y = bottomY + margin
    }
  }

  return { x, y }
})

function onSelection(e) {
  selectedObj.value = null
  editableProps.value = {}
  selectedObjectSubMenu.value = {}
  doesSelectionContainsModelDetails.value = false
  doesSelectionContainsArticleDetails.value = false
  const objs = props.whiteboard?.canvas.getActiveObjects() as Array<IWbObject>
  const selection = props.whiteboard?.canvas.getActiveObject()
  if (!objs || !objs.length || !selection) {
    toolbarVisible.value = false
    dropdownVisible.value = ''
    return
  }

  // if (objs.some(itm => !(itm as any).editableProps || itm.type !== objs[0].type)) {
  //   toolbarVisible.value = false
  //   dropdownVisible.value = ''
  //   return
  // }

  // if later decided to show actions that are uncommon between objects then while performing the action, the action should be performed on supported objects (check while performing action)
  const commonSubMenu = Object.fromEntries((Object.entries((objs[0] as any).actions || {})).filter((item: Array<any>) => item[1].showInSubMenu)) as Record<string, IWObjectActions>
  for (let i = 0; i < objs.length; i++) {
    // create common subMenu
    if (!isEmpty(commonSubMenu) && i !== 0) { // if commonSubMenu is already empty then don't repeat the loop on actions(not required for index 0 as well)
      for (const actionName in commonSubMenu) {
        if (!objs[i].actions?.hasOwnProperty(actionName) || actionName === 'copyModel') { // copy model should be visible only if 1 supported object is selected
          delete commonSubMenu[actionName]
        }
      }
    }

    if (objs[i].type === whiteboardConstants.objectTypes.modelDetails) {
      doesSelectionContainsModelDetails.value = true
    }
    if (objs[i].type === whiteboardConstants.objectTypes.articleDetails) {
      doesSelectionContainsArticleDetails.value = true
    }
  }
  if (objs.length === 1) {
    for (const actionName in commonSubMenu) {
      if (commonSubMenu[actionName].multiple && commonSubMenu[actionName].multiple === true) {
        delete commonSubMenu[actionName]
      }
    }
  }

  selectedObjectSubMenu.value = commonSubMenu // later on can implement actions that should appear on main menu

  selObjCoords.value = selection.getCoords(true)

  selectedObj.value = (objs[0] as any)
  lockProp.value = false
  if (selectedObj.value.editableProps) {
    editableProps.value = selectedObj.value.editableProps
    if (editableProps.value.hasOwnProperty('border')) {
      const prop = selectedObj.value.getProp('border')
      borderPropThickness.value = prop.strokeWidth || 1
      borderPropColor.value = prop.borderColor || '#000000'
    }
    if (editableProps.value.hasOwnProperty('color')) {
      const prop = selectedObj.value.getProp('color')
      colorProp.value = prop.backgroundColor || prop.color || '#93d275'
    }
    if (editableProps.value.hasOwnProperty('font')) {
      const prop = selectedObj.value.getProp('font')
      fontProp.value = prop.font || 'Helvetica'
    }
    if (editableProps.value.hasOwnProperty('fontSize')) {
      const prop = selectedObj.value.getProp('fontSize')
      fontSizeProp.value = prop.fontSize || '-1'
    }
    if (editableProps.value.hasOwnProperty('textColor')) {
      const prop = selectedObj.value.getProp('textColor')
      textColorProp.value = prop.textColor || '#000000'
    }
    if (editableProps.value.hasOwnProperty('textAlign')) {
      const prop = selectedObj.value.getProp('textAlign')
      textColorProp.value = prop.textAlign || 'center'
    }
    if (editableProps.value.hasOwnProperty('bold')) {
      const prop = selectedObj.value.getProp('bold')
      boldProp.value = prop.bold || false
    }
    if (editableProps.value.hasOwnProperty('italic')) {
      const prop = selectedObj.value.getProp('italic')
      italicProp.value = prop.italic || false
    }
    if (editableProps.value.hasOwnProperty('size')) {
      const prop = selectedObj.value.getProp('size')
      sizeProp.value = prop.size || 'M'
    }
    if (editableProps.value.hasOwnProperty('shapeType')) {
      const prop = selectedObj.value.getProp('shapeType')
      shapeTypeProp.value = prop.shapeType || 'rectangle'
    }
    if (editableProps.value.hasOwnProperty('frameSize')) {
      const prop = selectedObj.value.getProp('frameSize')
      frameSizeProp.value = prop.frameSize || 'custom'
    }
    if (editableProps.value.hasOwnProperty('lock')) {
      const prop = selectedObj.value.getProp('lock')
      lockProp.value = prop.lock || false
    }
    if (editableProps.value.hasOwnProperty('imageType')) {
      const prop = selectedObj.value.getProp('imageType')
      assetKeyProp.value = prop.assetKey || ''
      availableAssets.value = selectedObj.value.assets
    }
    if (editableProps.value.hasOwnProperty('scale')) {
      const prop = selectedObj.value.getProp('scale')
      scaleProp.value = prop.scale || 100
    }
    if (editableProps.value.hasOwnProperty('showLabels')) {
      const prop = selectedObj.value.getProp('showLabels')
      showLabelsProp.value = !!prop.showLabels
    }
    if (editableProps.value.hasOwnProperty('attributes')) {
      const prop = selectedObj.value.getProp('attributes')
      attributesProp.value = prop.attributes || []
    }
    toolbarVisible.value = true
  }
  if (e.e) {
    propDropdownPos.value = { x: e.e.x - 40, y: e.e.y - 40 }
  }
  else if (e.selected && e.selected.length === 1) {
    propDropdownPos.value = { x: e.selected[0].oCoords.tl.x, y: e.selected[0].oCoords.tl.y }
  }
}

function onOpenSelection(e: PointerEvent | MouseEvent, type: WbObjectPropType) {
  dropdownVisible.value = dropdownVisible.value === type ? '' : type
  propDropdownPos.value = { x: e.x - 74, y: toolbarPos.value.y + toolbarSize.height.value + 10 }
}

function onSelectedObjectSubMenuClick(action: string) {
  const activeObject = props.whiteboard!.canvas.getActiveObject()
  if (activeObject === null) { return }

  const objectsKey = '_objects'
  let objects
  if (activeObject.type === 'activeSelection') {
    objects = activeObject[objectsKey]
  }
  else {
    objects = [activeObject]
  }
  props.whiteboard?.canvas.discardActiveObject()
  switch (action) {
    case 'rename':
      emit('openTextEditingDialog', activeObject as IWbObject)
      break
    case 'delete':
      props.whiteboard!.removeObjects(objects, true)
      break
    case 'selectSimilar': {
      const matchingObjects = Utility.getSimilarObjects(props.whiteboard!.canvas, objects)
      if (matchingObjects.length > 0) {
        const selection = new fabric.ActiveSelection(matchingObjects, { canvas: props.whiteboard!.canvas })
        props.whiteboard!.canvas.setActiveObject(selection)
        props.whiteboard!.canvas.requestRenderAll()
      }
      break
    }
    case 'bringFront':
      activeObject.bringToFront()
      break
    case 'sendBack':
      activeObject.sendToBack()
      break
    case 'copyLink': {
      const wbObject = activeObject as IWbObject
      if (wbObject.id) {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.set('moveToObject', wbObject.id)
        const link = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`
        navigator.clipboard.writeText(link)
          .then(() => notificationsStore.addNotification({ message: t('notifications.linkCopied'), type: 'Success' }))
      }
      break
    }
    case 'copyModel':
      emit('copyModel', activeObject as WbArticleImage | WbModelImage)
      break
    case 'unGroup':
      props.whiteboard!.ungroupObject(activeObject, true)
      break
    case 'group':
      props.whiteboard!.groupObjects(objects, {}, true)
      break
  }
}

function onAddModelDetails() {
  dropdownVisible.value = ''
  const activeObjects = props.whiteboard?.canvas.getActiveObjects()
  props.whiteboard!.canvas.discardActiveObject()
  const addedObjects: IWbObject[] = []
  activeObjects?.forEach(async (activeObject: any) => {
    let modelDetails: WbModelDetails | null = null
    if (activeObject.type === whiteboardConstants.objectTypes.modelImage) {
      const options = {
        left: activeObject.aCoords.bl.x,
        top: activeObject.aCoords.bl.y,
      }
      if (activeObject.modelNumber) {
        modelDetails = await WbModelDetails.loadModelDetailsByNumber(activeObject.modelNumber, activeObject.catalogCode, options)
      }
    }
    if (modelDetails) {
      // set article details object height otherwise it will take random height and while selection unnecessary white space is selected
      modelDetails.setProp('height', 52)
      props.whiteboard?.addObjects([modelDetails], true)
      addedObjects.push(modelDetails)
    }
  })
  if (activeObjects?.length) {
    const selection = new fabric.ActiveSelection(addedObjects, { canvas: props.whiteboard!.canvas })
    props.whiteboard!.canvas.setActiveObject(selection)
    props.whiteboard?.canvas.requestRenderAll()
  }
}

function onAddArticleDetails() {
  dropdownVisible.value = ''
  const objs = props.whiteboard?.canvas.getActiveObjects()
  props.whiteboard!.canvas.discardActiveObject()
  const addedObjects: IWbObject[] = []
  objs?.forEach(async (o: any) => {
    let articleDetails: WbArticleDetails | null = null
    if (o.type === whiteboardConstants.objectTypes.articleImage) {
      const opt = { left: o.aCoords.bl.x, top: o.aCoords.bl.y }
      if (o.articleId) {
        articleDetails = await WbArticleDetails.loadArticleDetailsById(o.catalogCode, o.articleId, o.objectId, o.isRequest, opt)
      }
    }
    if (articleDetails) {
      // set article details object height otherwise it will take random height and while selection unneccesary white space is selected
      articleDetails.setProp('height', 52)
      props.whiteboard?.addObjects([articleDetails], true)
      addedObjects.push(articleDetails)
    }
  })
  if (objs?.length) {
    const selection = new fabric.ActiveSelection(addedObjects, { canvas: props.whiteboard!.canvas })
    props.whiteboard!.canvas.setActiveObject(selection)
    props.whiteboard?.canvas.requestRenderAll()
  }
}

function onAssignImage() {
  assignImageRef.value?.click()
}

function onAddDiscussion() {
  dropdownVisible.value = ''
  const opt = { left: 0, top: 0 }
  const objs = props.whiteboard?.canvas.getActiveObjects()
  props.whiteboard!.canvas.discardActiveObject()
  objs?.forEach(async (o: any) => {
    if (o.type !== whiteboardConstants.objectTypes.discussion) {
      opt.left = (o.aCoords.tl.x + ((o.width * o.scaleX) / 2))
      opt.top = (o.aCoords.tl.y + ((o.height * o.scaleY) / 2))
      const discussionIcon = new WbDiscussionIcon(opt)
      discussionIcon.bringToFront()
      if (discussionIcon) {
        props.whiteboard?.addObjects([discussionIcon], true)
        // NOTE: As discussed with saad currently not going to select the comment after adding the comment, whenuser select then comment then its dropdown will be displayed
        // props.whiteboard?.canvas.setActiveObject(discussionIcon)
      }
    }
  })
  if (objs?.length) {
    props.whiteboard?.canvas.requestRenderAll()
    // propDropdownPos.value = { x: opt.left + 30, y: opt.top - 30 }
  }
}

function onBorderPropChange(color: string, thickness: number) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('border', { borderColor: color, strokeWidth: thickness })
  })
  borderPropColor.value = color
}

function onColorPropChange(color: string) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('color', { backgroundColor: color })
  })
  colorProp.value = color
}

function onFontPropChange(font: string) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('font', { font })
  })
  fontProp.value = font
}

function onFontSizePropChange(fontSize: string) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('fontSize', { fontSize })
  })
  fontSizeProp.value = fontSize
}

function onTextColorPropChange(textColor: string) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('textColor', { textColor })
  })
  textColorProp.value = textColor
}

function onTextAlignPropChange(textAlign: string) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('textAlign', { textAlign })
  })
  textAlignProp.value = textAlign
}

function onBoldPropChange(bold: boolean) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('bold', { bold })
  })
  boldProp.value = bold
}

function onItalicPropChange(italic: boolean) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('italic', { italic })
  })
  italicProp.value = italic
}

function onSizePropChange(size: string) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('size', { size })
  })
  sizeProp.value = size
}

function onShapeTypePropChange(shapeType: ShapeType) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('shapeType', { shapeType })
  })
  shapeTypeProp.value = shapeType
}

function onFrameSizePropChange(frameSize: FrameSize) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('frameSize', { frameSize })
  })
  frameSizeProp.value = frameSize
}

function onLockPropChange(lock: boolean) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('lock', { lock })
  })
  lockProp.value = lock
}

function onAssetPropChange(asset: DuneAsset) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('imageType', { assetKey: asset.Key })
  })
  assetKeyProp.value = asset.Key
}

function onScalePropChange(scale: number) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('scale', { scale })
  })
  scaleProp.value = scale
}

function onShowLabelsPropChange(showLabels: boolean) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('showLabels', { showLabels })
  })
  showLabelsProp.value = showLabels
}

function onAttributePropChange(attributes: string[]) {
  const objs = props.whiteboard?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('attributes', { attributes })
  })
}

function onDeleteDiscussion() {
  const obj = props.whiteboard?.canvas.getActiveObject() as IWbObject
  if (obj) {
    props.whiteboard?.removeObjects([obj], true)
  }
}

function onAssignImageChange() {
  if (userStore.activeCatalog && assignImageRef.value?.files?.length && selectedObj.value) {
    const selectedArticleImage = selectedObj.value as WbArticleImage
    openPlaceholderImageDialog(selectedArticleImage, assignImageRef.value.files[0])
    resetAssignImage()
  }
}

function openPlaceholderImageDialog(articleImage: WbArticleImage, file: File) {
  if (userStore.activeCatalog) {
    appConfig.DB!.getMyArticlesById(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articleImage.articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
      .then(([article]) => {
        // as discussed with Andre, changing file name to ImageSet_Key
        const filename = `${article.ArticleNumber}_${userStore.activeCatalog!.Config.WhiteboardImagePlaceholderOptions.key}.${file.name.split('.').pop()}`
        const requestObj: CreateUpdateAssetModel = {
          Context: userStore.activeCatalog!.DuneContext,
          ContextKey: userStore.activeCatalog!.ContextReadWriteKey,
          ImageSet: article.ArticleNumber,
          Key: userStore.activeCatalog!.Config.WhiteboardImagePlaceholderOptions.key,
          SortOrder: userStore.activeCatalog!.Config.WhiteboardImagePlaceholderOptions.sortOrder,
          file: new File([file], filename),
        }
        placeholderImageDialogRef.value?.showDialog(article, requestObj)
      })
  }
}

function resetAssignImage() {
  if (assignImageRef.value?.files?.length) {
    assignImageRef.value.files = null
    assignImageRef.value.value = ''
  }
}

async function onAssignImageConfirm(article: MyArticle, assetId: string) {
  const res = await utils.tryAsync(getArticleAssets(userStore.activeCatalog!.DuneContext, userStore.activeCatalog!.ContextReadKey, article.ArticleNumber, userStore.activeCatalog?.Config.NewestImageAssetKeyList))
  if (res.success) {
    availableAssets.value = res.result
  }
  else {
    availableAssets.value = []
  }

  // from article toolbar action
  if (selectedObj.value) {
    selectedObj.value.assets = availableAssets.value
  }

  // by dropping image on article image
  if (selectedArticleImage) {
    const obj = props.whiteboard?.canvas.getActiveObject() as IWbObject
    if (obj?.type === whiteboardConstants.objectTypes.image) {
      props.whiteboard?.removeObjects([obj], true)
    }
    selectedArticleImage.assets = availableAssets.value
    props.whiteboard?.canvas.setActiveObject(selectedArticleImage)
  }

  // if current article image type(assetKey) is as same as userStore.activeCatalog.Config.WhiteboardImagePlaceholderOptions.key (then one uploaded from whiteboard) then update the article image after assigning new image
  if (!utils.isValidStringValue(assetKeyProp.value) || (userStore.activeCatalog && assetKeyProp.value === userStore.activeCatalog.Config.WhiteboardImagePlaceholderOptions.key)) {
    const selectedAsset = availableAssets.value.find(availableAsset => availableAsset.Id === assetId)
    if (selectedAsset) {
      onAssetPropChange(selectedAsset)
    }
  }
  resetSelectedArticleImage()
  resetAssignImage()
}
/**
 * @description: if there are any selectedArticleImage then un-highlight (dim) it and assign undefined to it
 */
function resetSelectedArticleImage() {
  if (selectedArticleImage) {
    selectedArticleImage.dim()
  }
  selectedArticleImage = undefined
}
</script>
