<template>
  <tx-dialog
    v-model="visible" :title="t('general.targetsSummary')" body-classes="p-0"
    width="90%" height="90%" @cancel="doCancel"
  >
    <div class="w-full h-full grow flex flex-col overflow-hidden">
      <tx-data-table
        :data="data" :columns="columns" :visible-columns="visibleColumns" :loading="loading"
        :title="t('general.targetsSummary')" :item-actions="itemActions" :list-actions="listActions"
        @refresh="onLoad"
      />
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxDataTable from '@/shared/components/txDataTable'
import { getTargetsReport } from '@/api/t1/target'
import { useUserStore } from '@/store/userData'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { AttributeType } from '@/models/catalogAttribute'
import utils from '@/services/utils'

const emit = defineEmits<{
  (e: 'cancel'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const visible = ref(false)
const loading = ref(false)
const data = ref<any[]>([])
const columns = ref<ITxDataTableColumn[]>([])
const visibleColumns = ref<ITxDataTableVisibleColumn[]>([])

const itemActions = computed<ITxDataTableItemAction[]>(() => ([]))
const listActions = computed<ITxDataTableListAction[]>(() => ([]))

function open() {
  visible.value = true
  onLoad()
}

function onLoad() {
  data.value = []
  if (userStore.activeCatalog) {
    loading.value = true
    getTargetsReport(userStore.activeCatalog.CatalogCode)
      .then((resp) => {
        if (resp && resp.data && resp.data.length) {
          const columnsMap: Record<string, ITxDataTableColumn> = {}
          if (columns.value.length) {
            columns.value.forEach((column) => {
              columnsMap[column.property] = column
            })
          }

          let isChanged = false

          const responseProperties = new Set<string>()
          resp.data.forEach((item) => {
            for (const key in item) {
              if (!columnsMap.hasOwnProperty(key)) {
                visibleColumns.value.push({
                  property: key,
                  order: 1,
                  width: 0.12,
                })
                columnsMap[key] = {
                  property: key,
                  title: key.split('|').join(' '),
                  type: AttributeType.Nvarchar,
                }
                isChanged = true
              }
              responseProperties.add(key)
            }
            data.value.push(item)
          })

          if (columns.value.length) {
            const visibleColumnIndexMap = utils.arrayToStringIndex(visibleColumns.value, 'property')

            for (const key in columnsMap) {
              if (!responseProperties.has(key)) {
                delete columnsMap[key]
                if (visibleColumnIndexMap.hasOwnProperty(key)) {
                  visibleColumns.value.splice(visibleColumnIndexMap[key], 1)
                }
                isChanged = true
              }
            }
          }

          if (isChanged) {
            columns.value = Object.values(columnsMap)
          }
        }
      })
      .catch(e => console.error(e))
      .finally(() => {
        loading.value = false
      })
  }
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

defineExpose({
  open,
})
</script>
